@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import "react-responsive-modal/styles.css";
@import "drawer.scss";

//swiperjs
@import "swiper.scss";
@import "swiper/css";
@import "swiper/css/navigation";
@import "swiper/css/pagination";
@import "swiper/css/scrollbar";
@import "swiper/css/effect-coverflow";

// Html Body
html,
body {
  position: fixed;
  overflow: hidden;
  // overflow-y: auto !important;
  font-family: "Inter", sans-serif;
}

//* Next
#__next {
  width: 100vw;
  height: 100dvh;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background-size: cover;
  background-position: bottom;
  background-color: white;
}

.dynamic-vh {
  height: 100vh !important;
  @media (max-width: 1024px) {
    height: 100dvh !important;
  }
}
.scroll-auto {
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

//* NProgress
#nprogress {
  pointer-events: none;

  //* Bar
  .bar {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 2.5px;
    background: #1c6162;
  }
}

.flip {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.scroll-shadow {
  box-shadow: rgb(0 0 0 / 4%) 0px 4px 4px;
}

.container-max {
  max-width: 1198px;
  padding: 0 48px;
}

.inner-style {
  * {
    font-family: "Inter", sans-serif !important;
    color: #202020 !important;
    background-color: #fff !important;
  }
  ul {
    list-style-type: disc 10px !important;
    list-style-position: inside 10px !important;
    margin-bottom: 10px !important;
    li {
      padding: 0 5px 10px !important;
    }
  }
  &--title {
    * {
      font-size: 22px !important;
      font-weight: 600 !important;
      @media (max-width: 1024px) {
        font-size: 16px !important;
      }
    }
  }
  &--option {
    * {
      font-size: 24px !important;
      @media (max-width: 1024px) {
        font-size: 18px !important;
      }
    }
  }
}

.invisible-scrollbar::-webkit-scrollbar {
  display: none;
}

//*  Demonstrate a "app scrollbar" scrollbar

.no-scrollbar::-webkit-scrollbar {
  display: none;
}
.app-scrollbar::-webkit-scrollbar {
  width: 4px;
  background-color: #eee;
  border-radius: 30px;
  @media (max-width: 1024px) {
    display: none;
  }
}
.app-scrollbar::-webkit-scrollbar-thumb {
  background-color: #c7c7ce;
  border-radius: 30px;
}
.app-scrollbar--lg::-webkit-scrollbar {
  width: 10px;
}
.app-scrollbar--lg::-webkit-scrollbar-thumb {
  border-radius: 50px;
}
