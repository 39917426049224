.carousel {
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 40px;
  }
  .pagination-bullet-primary {
    background-color: #33bc92;
  }
  .swiper-pagination-bullet {
    width: 20px;
    height: 20px;
    border-radius: 7px;
    transition: all 0.5s ease;
    opacity: 0.5;
  }
  .swiper-pagination-bullet-active.pagination-bullet-primary {
    background-color: #33bc92;
    width: 50px;
    opacity: 1;
  }
}
